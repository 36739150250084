<template>
  <el-dialog :visible.sync="dialog" title="库存明细" append-to-body :close-on-click-modal="false" width="840px" @closed="goods = false; store = [];">
    <template v-if="goods">
      <div>
        <b>{{goods.name}}</b>
      </div>
      <div class="fc-g">{{$goodsSpecConvert(goods.specs)}}</div>
      <el-table :data="store" border empty-text="该商品当前没有库存信息" v-loading="loading" style="margin-top: 20px;">
        <el-table-column prop="warehouseName" width="160px" label="库房名称" />
        <el-table-column prop="count" width="80px" label="库存数量" />
        <el-table-column label="成本均价" width="140" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
          <template slot-scope="scope">
            <price-input v-model="scope.row.goodsPrice" :min="0" size="mini" style="width: 100%;" />
          </template>
        </el-table-column>
        <el-table-column label="成本合计" width="100" align="center" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
          <template slot-scope="scope">{{$price(scope.row.goodsPrice * scope.row.count)}}</template>
        </el-table-column>
        <el-table-column label="备注">
          <div prop="info" slot-scope="scope">
            <el-input type="textarea" v-model="scope.row.info" placeholder="请输入内容"></el-input>
          </div>
        </el-table-column>
      </el-table>

      <div class="h c" style="margin-top: 20px;" v-if="store && store.length">
        <div style="width: 240px; text-align: right;">统一设置成本均价：</div>
        <price-input v-model="tyPrice" :min="0" style="width: 120px; margin: 0 10px;" />
        <el-button type="primary" plain @click="tyPriceSet">设置</el-button>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="submiting" type="primary" @click="doSave" :disabled="submiting">保存</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import checkPermission from "@/utils/permission";
import { getGoodsById, editGoodsInfo } from "@/api/warehouse";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      goods: null,
      store: [],
      submiting: false,
      tyPrice: 0,
    };
  },
  methods: {
    checkPermission,
    reset(goods, warehouseIds) {
      if (goods) {
        this.goods = goods;
        this.loading = true;        
        getGoodsById(goods.id, warehouseIds)
          .then((res) => {
            this.store = res;
          })
          .finally((_) => {
            this.loading = false;
          });
        this.dialog = true;
      }
    },
    tyPriceSet() {
      this.store.forEach(d => {
        d.goodsPrice = this.tyPrice;
      });
    },
    doSave() {
      this.saving = true;
      this.submiting = true;
      editGoodsInfo(this.store).finally((_) => {
        this.submiting = false;
        this.dialog = false;
        this.$parent.init();
      });
    },
  },
};
</script>